import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";

import { withTheme, withStyles, Tabs, Tab, Box, Modal, IconButton } from "@material-ui/core";

import { ProgressOrError, formatMessageWithValues, Helmet, withModulesManager, journalize } from "@openimis/fe-core";
import AccreditationDataView from "./AccreditationDataView";
import CloseIcon from "@material-ui/icons/Close";

import AccreditationMasterPanel from "./AccreditationMasterPanel";
import Results from "./Results";
import Formslist from "./Formslist";
import { initiateAccreditationDetail, initiateAndDeployForms, startOrStopAcc } from "../actions";
import { approveOrDeclineAccreditationDetail } from "../actions";
import UsersPanel from "./UsersPanel";
import { DETAIL_CONFIRMED, DETAIL_FORM_DEPLOYED, DETAIL_IN_REVIEW } from "../constants";

const styles = (theme) => ({
  page: theme.page,
  lockedPage: theme.page.locked,
  paper: theme.paper.paper,
  modalContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    outline: "none",
  },
  modalCloseButton: {
    marginTop: theme.spacing(0),
  },
});

class AccreditationForm extends Component {
  state = {
    selectedTab: 0, // Default tab
    qrCodeModalOpen: false, // State to control QR code modal
    user: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  closeModal = () => {
    this.setState({ qrCodeModalOpen: false });
  };

  bulkInitiateAndDeployOdkFormsHandler = (forms) => {
    const { edited, isInitialized } = this.props;
    if (edited && edited[0].uuid) {
      this.props.initiateAndDeployForms(forms, edited[0]?.uuid, "Initiated and deployed Forms");
    }
  };

  renderTabPanel(index, updateAttribute, edited) {
    switch (index) {
      case 0:
        return (
          <Formslist
            uuid={edited && edited[0]?.uuid}
            accId={edited && edited[0]?.id}
            formType={1}
            detailType={"acc"}
            bulkInitiateAndDeployOdkForms={this.bulkInitiateAndDeployOdkFormsHandler}
          />
        );
      case 1:
        return (
          <UsersPanel detailUuid={edited && edited[0]?.uuid} projectType="acc" projectId={edited && edited[0]?.id} />
        );
      case 2:
        return <AccreditationDataView edited={edited} detailType="acc" />;
      default:
        return null;
    }
  }

  initiateAccreditationDetail = () => {
    const { edited } = this.props;
    if (edited && edited[0].uuid) {
      this.props.initiateAccreditationDetail(edited[0]?.uuid, "Initiated Acreditation Detail");
    }
  };

  handleApproveOrRejectAccreditation = (state, clientMutationLabel) => {
    const { edited } = this.props;
    if (edited && edited[0].uuid) {
      this.props.approveOrDeclineAccreditationDetail(edited[0]?.uuid, state, clientMutationLabel);
    }
  };

  handleUserChange = (user) => {
    this.setState({ user: user });
  };

  addUser = () => {
    if (this.state.user) {
    }
  };

  startOrStopAccreditation = (action) => {
    const { edited, startOrStopAcc } = this.props;
    if (edited && edited[0].uuid) {
      startOrStopAcc(
        { uuid: edited[0]?.uuid, action: action },
        `${action === "start" ? "Started" : "Stopped"} Accreditation Process`,
      );
    }
  };

  render() {
    const { classes, intl, edited, updateAttribute, projectUuid, mutations } = this.props;
    const { selectedTab } = this.state;

    const accreditationState = edited && edited[0]?.state;

    return (
      <div className={clsx(false && classes.lockedPage)}>
        <Helmet
          title={formatMessageWithValues(intl, "location", "healthFacility.edit.page.title", {
            code: "accreditation",
          })}
        />
        <ProgressOrError progress={false} error={true} />
        <AccreditationMasterPanel
          edited={edited}
          updateAttribute={updateAttribute}
          initiateAccreditationDetail={this.initiateAccreditationDetail}
          handleApproveOrRejectAccreditation={this.handleApproveOrRejectAccreditation}
          startOrStopAccreditation={this.startOrStopAccreditation}
        />
        {edited && edited[0]?.isInitialized && (
          <Fragment>
            <Tabs
              value={selectedTab}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.paper}
            >
              <Tab label="Assign Forms" />
              <Tab label="User" />
              <Tab label="Form Data" disabled={edited[0]?.state < DETAIL_IN_REVIEW} />
            </Tabs>

            <Box>{this.renderTabPanel(selectedTab, updateAttribute, edited)}</Box>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  mutation: state.accredation.mutation,
  submittingMutation: state.accredation.submittingMutation,
  mutations: state.core.mutations,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      initiateAccreditationDetail,
      approveOrDeclineAccreditationDetail,
      journalize,
      initiateAndDeployForms,
      startOrStopAcc,
    },
    dispatch,
  );
};

export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(AccreditationForm)))),
);
