import React from "react";
import { Box, Typography } from "@material-ui/core";
import PercentageChart from "./PercentageChart";

const CalculationResults = ({ calculations }) => {
  let parsedCalculations = [];

  try {
    // If calculations is a string, replace single quotes with double quotes and parse it
    parsedCalculations = typeof calculations === "string" ? JSON.parse(calculations.replace(/'/g, '"')) : calculations;

    // Ensure it's an array
    if (!Array.isArray(parsedCalculations)) {
      parsedCalculations = [];
    }
  } catch (error) {
    console.error("Invalid calculations format:", error);
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Submission Statistics
      </Typography>

      {parsedCalculations.length === 0 ? (
        <Typography color="error">No valid calculation data available.</Typography>
      ) : (
        <Box display="flex" justifyContent="space-around" flexWrap="wrap">
          {parsedCalculations.map((item, index) => {
            const entries = Object.entries(item);
            if (entries.length === 0) return null;

            const [key, value] = entries[0];
            const label = key.replace(/_/g, " ");

            return <PercentageChart key={index} value={value} maxValue={100} label={label} />;
          })}
        </Box>
      )}
    </Box>
  );
};

export default CalculationResults;
