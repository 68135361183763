import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { withModulesManager, withHistory } from "@openimis/fe-core";
import { fetchAccredationDetailFormData } from "../actions";
import AccreditationFormCalculation from "./AccreditationFormCalculation";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  sectionPaper: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  },
  metaLabel: {
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  listItem: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: theme.spacing(4),
  },
});

class FormViewer extends Component {
  componentDidMount() {
    const { uuid, detailType } = this.props;
    if (uuid && detailType) {
      this.props.fetchAccredationDetailFormData(uuid, detailType);
    }
  }

  renderObject = (obj) => {
    const filteredEntries = Object.entries(obj).filter(([key]) => key !== "uuid");

    return (
      <Grid container spacing={2}>
        {filteredEntries.map(([key, value], idx) => (
          <Grid item xs={12} sm={6} key={idx}>
            <ListItem className={this.props.classes.listItem}>
              <ListItemText
                primary={
                  <span>
                    <strong className={this.props.classes.metaLabel}>{this.formatKey(key)}:</strong>{" "}
                    {typeof value === "object" && value !== null
                      ? this.renderObject(value)
                      : value?.toString() || "N/A"}
                  </span>
                }
              />
            </ListItem>
          </Grid>
        ))}
      </Grid>
    );
  };

  renderSubmission = (data) => {
    let parsedData = null;
    try {
      parsedData = data ? JSON.parse(data?.data)?.value : null;
    } catch (error) {
      console.error("Error parsing data:", error);
    }

    return parsedData && parsedData.length > 0 ? (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" className={this.props.classes.title}>
            Form Data
          </Typography>
          <Divider />
        </Grid>

        {parsedData.map((item, idx) => (
          <Grid item xs={12} key={idx}>
            <Paper className={this.props.classes.sectionPaper}>
              {this.renderObject(item)}

              {/* Adding CalculationResults for each submission */}
              <Box mt={2}>
                <AccreditationFormCalculation
                  accreditationDetailFormUuid={this.props.uuid}
                  odkInstanceId={item.meta?.instanceID}
                />
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    ) : (
      <Typography variant="body1" color="textSecondary">
        No Data collected yet
      </Typography>
    );
  };

  formatKey = (key) => key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());

  render() {
    const { classes, fetchingAccreditationDetailsFormData, accreditationDetailsFormData } = this.props;

    return (
      <Box className={classes.root}>
        {fetchingAccreditationDetailsFormData ? (
          <Box className={classes.loadingContainer}>
            <CircularProgress />
          </Box>
        ) : (
          this.renderSubmission(accreditationDetailsFormData)
        )}
      </Box>
    );
  }

  // PropTypes validation
  static propTypes = {
    classes: PropTypes.object.isRequired,
    uuid: PropTypes.string,
    fetchAccredationDetailFormData: PropTypes.func.isRequired,
    fetchingAccreditationDetailsFormData: PropTypes.bool,
    accreditationDetailsFormData: PropTypes.object,
  };
}

const mapStateToProps = (state) => ({
  accreditationDetailsFormData: state.accredation.accreditationDetailsFormData,
  fetchingAccreditationDetailsFormData: state.accredation.fetchingAccreditationDetailsFormData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchAccredationDetailFormData }, dispatch);

export default withModulesManager(
  injectIntl(withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(FormViewer))))),
);
