import React, { Component } from "react";
import {
  Paper,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@material-ui/core";
import { withTheme, withStyles } from "@material-ui/core/styles";
import FormViewer from "./FormViewer";

import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  withHistory,
  historyPush,
  formatMessage,
  Helmet,
  clearCurrentPaginationPage,
  withModulesManager,
} from "@openimis/fe-core";

import { fetchAccredationDetailForms, fetchQADetailForms } from "../actions";

const styles = (theme) => ({
  page: theme.page,
  paper: theme.paper.paper,
  title: theme.paper.title,
  formContainer: {
    marginTop: theme.spacing(1),
  },
});

class AccreditationDataView extends Component {
  state = {
    open: false,
    selectedForm: null,
    forms: null,
  };

  componentDidMount() {
    if (this.props.edited[0] && this.props.detailType === "acc") {
      this.props.fetchAccredationDetailForms(this.props.edited[0]?.id);
    } else {
      this.props.fetchQADetailForms(this.props.edited.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { fetchedAccreditationDetailsForms, accreditationDetailsForms, fetchedQADetailsForms, qaDetailsForms } =
      this.props;

    if (
      prevProps.fetchedAccreditationDetailsForms !== fetchedAccreditationDetailsForms &&
      fetchedAccreditationDetailsForms
    ) {
      this.setState({
        forms: accreditationDetailsForms,
      });
    }

    if (prevProps.fetchedQADetailsForms !== fetchedQADetailsForms && fetchedQADetailsForms) {
      this.setState({
        forms: qaDetailsForms,
      });
    }
  }

  handleOpen = (form) => {
    this.setState({ open: true, selectedForm: form });
  };

  handleClose = () => {
    this.setState({ open: false, selectedForm: null });
  };

  handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  render() {
    const { classes, accreditationDetailsForms, qaDetailsForms, detailType } = this.props;
    const { open, selectedForm, formData } = this.state;

    return (
      <div>
        <Paper className={classes.paper}>
          <Typography className={classes.title}>Accreditation Data View</Typography>
          <div className={classes.page}>
            {this.state.forms?.map((form) => (
              <Typography
                key={form.id}
                variant="outlined"
                color="primary"
                onClick={() => this.handleOpen(form)}
                style={{
                  margin: "10px",
                  display: "block",
                  cursor: "pointer",
                  border: "solid 1px",
                  borderRadius: "5px",
                  padding: 10,
                }}
              >
                {form?.odkForm.formName}
              </Typography>
            ))}
          </div>
        </Paper>

        {/* Modal Component */}
        <Dialog open={open} onClose={this.handleClose} maxWidth="lg" fullWidth>
          <DialogTitle>{selectedForm ? selectedForm.odkForm.formName : "Select a Form"}</DialogTitle>
          <DialogContent className={classes.formContainer}>
            <FormViewer uuid={selectedForm?.uuid} detailType={detailType} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  module: state.core?.savedPagination?.module,
  accreditationDetailsForms: state.accredation.accreditationDetailsForms,
  fetchingAccreditationDetailsForms: state.accredation.fetchingAccreditationDetailsForms,
  fetchedAccreditationDetailsForms: state.accredation.fetchedAccreditationDetailsForms,
  errorAccreditationDetailsForms: state.accredation.errorAccreditationDetailsForms,
  qaDetailsForms: state.accredation.qaDetailsForms,
  fetchingQADetailsForms: state.accredation.fetchingQADetailsForms,
  fetchedQADetailsForms: state.accredation.fetchedQADetailsForms,
  errorQADetailsForms: state.accredation.errorQADetailsForms,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchAccredationDetailForms, fetchQADetailForms }, dispatch);
};
export default withModulesManager(
  injectIntl(
    withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(AccreditationDataView)))),
  ),
);
