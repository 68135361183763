import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles, withTheme, Paper, Typography, FormControlLabel, Checkbox, Box, Button } from "@material-ui/core";
import { fetchForms, deployForms, fetchAccredationDetailForms, fetchQADetailForms } from "../actions"; // Assuming this action exists

const styles = (theme) => ({
  paper: theme.paper.paper,
  title: theme.paper.title,
  page: theme.page,
});

class FormsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formToPublish: [],
    };
  }

  componentDidMount() {
    const { formType, accId, detailType } = this.props;
    if (formType) {
      this.props.fetchForms(formType);
    }
    if (accId && detailType === "acc") {
      this.props.fetchAccredationDetailForms(accId);
    }
    if (accId && detailType === "qa") {
      this.props.fetchQADetailForms(accId);
    }
  }

  handleCheckboxChange = (uuid, isDisabled) => {
    if (isDisabled) return;
    this.setState(({ formToPublish }) => ({
      formToPublish: formToPublish.includes(uuid)
        ? formToPublish.filter((id) => id !== uuid)
        : [...formToPublish, uuid],
    }));
  };

  handleDeployForms = () => {
    const { uuid, deployForms, detailType, bulkInitiateAndDeployOdkFormsHandler } = this.props;
    const { formToPublish } = this.state;
    deployForms(formToPublish, uuid, detailType, "Deployed Forms");
  };

  isDeployForms = () => !this.state.formToPublish.length || !this.props.uuid;

  render() {
    const {
      classes,
      forms,
      accDetailUuid,
      bulkInitiateAndDeployOdkFormsHandler,
      qaDetailsForms,
      accreditationDetailsForms,
    } = this.props;
    const { formToPublish } = this.state;

    // Extract accreditation form UUIDs for comparison
    const accreditedFormUUIDs = accreditationDetailsForms
      ? new Set(accreditationDetailsForms?.map((edge) => edge.odkForm.uuid))
      : new Set(qaDetailsForms?.map((edge) => edge.odkForm.uuid));
    return (
      <div>
        <Paper className={classes.paper}>
          <Typography className={classes.title}>Forms List</Typography>
          <div className={classes.page}>
            {forms?.map((form, index) => {
              const isChecked = accreditedFormUUIDs?.has(form?.uuid);
              return (
                <div style={{ width: "100%" }} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        value={form?.uuid}
                        onChange={() => this.handleCheckboxChange(form?.uuid, isChecked)}
                        checked={isChecked || formToPublish.includes(form?.uuid)}
                        disabled={isChecked}
                      />
                    }
                    label={form?.formName}
                  />
                </div>
              );
            })}
            <Box display={"flex"} justifyContent={"end"}>
              <Button
                variant={"contained"}
                onClick={this.handleDeployForms}
                color="primary"
                disabled={this.isDeployForms()}
              >
                Publish Forms
              </Button>
            </Box>
          </div>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  forms: state.accredation.forms,
  accreditationDetailsForms: state.accredation.accreditationDetailsForms,
  fetchingAccreditationDetailsForms: state.accredation.fetchingAccreditationDetailsForms,
  fetchedAccreditationDetailsForms: state.accredation.fetchedAccreditationDetailsForms,
  errorAccreditationDetailsForms: state.accredation.errorAccreditationDetailsForms,
  fetchingQADetailsForms: state.accredation.fetchingQADetailsForms,
  fetchedQADetailsForms: state.accredation.fetchedQADetailsForms,
  qaDetailsForms: state.accredation.qaDetailsForms,
  errorQADetailsForms: state.accredation.errorQADetailsForms,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchForms, deployForms, fetchAccredationDetailForms, fetchQADetailForms }, dispatch);

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FormsList)));
