import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, Button, Typography, Paper } from "@material-ui/core";
import { withModulesManager, withHistory } from "@openimis/fe-core";
import AccreditationForm from "../components/AccreditationForm";
import { fetchAccredationDetail } from "../actions";

const styles = (theme) => ({
  paper: theme.paper.paper,
  title: theme.paper.title,
  page: theme.page,
});
class AccreditationDetailPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { accUuid } = this.props?.match?.params;
    if (accUuid) {
      this.props.fetchAccredationDetail(accUuid);
    }
  }
  render() {
    const {
      classes,
      fetchedAccreditationDetail,
      fetchingAccreditationDetail,
      accreditationDetail,
      errorAccreditationDetail,
    } = this.props;

    if (errorAccreditationDetail) {
      return <Typography>Something Happened</Typography>;
    }
    if (fetchingAccreditationDetail) {
      return (
        <Paper className={classes.paper}>
          <Typography className={classes.title}>Loading.....</Typography>
        </Paper>
      );
    }
    return (
      <div className={""}>
        <AccreditationForm edited={accreditationDetail} isInitialized={fetchedAccreditationDetail.isInitialized} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  module: state.core?.savedPagination?.module,
  accreditationDetail: state.accredation.accreditationDetail,
  fetchingAccreditationDetail: state.accredation.fetchingAccreditationDetail,
  fetchedAccreditationDetail: state.accredation.fetchedAccreditationDetail,
  errorAccreditationDetail: state.accredation.errorAccreditationDetail,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchAccredationDetail }, dispatch);

export default withModulesManager(
  injectIntl(
    withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(AccreditationDetailPage)))),
  ),
);
