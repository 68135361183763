import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Button, Box } from "@material-ui/core";
import { PublishedComponent } from "@openimis/fe-core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { withModulesManager, journalize, decodeId } from "@openimis/fe-core";
import { createODKUser, fetchAccredationUsers } from "../actions";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import OdkUsersList from "./OdkUsersList";
import AccreditationUserPicker from "../pickers/AccreditationUserPicker";
import { TextField, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const styles = (theme) => ({
  page: theme.page,
  paper: theme.paper.paper,
  title: theme.paper.title,

  userLable: {
    padding: 4,
    margin: 4,
    "&:hover": {
      backgroundColor: "#f0f0f0", // Change this color as needed
    },
    cursor: "pointer",
  },
});

function UsersPanel(props) {
  const { classes, submittingMutation, mutation, journalize, createODKUser, projectType, detailUuid, projectId } =
    props;

  const [user, setUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleAssignUser = () => {
    if (user) {
      createODKUser(selectedUser.id, projectType, detailUuid, "Assign User to Detail");
    }
  };

  // Monitor submittingMutation and mutation to trigger journalize
  useEffect(() => {
    if (submittingMutation && mutation) {
      journalize(mutation);
    }
    if (user) {
      props.fetchAccredationUsers(user);
    }
  }, [submittingMutation, mutation, journalize, user]);

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title}>Create Odk User</Typography>
      <div className={classes.page}>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              placeholder="Search..."
              fullWidth
              value={
                selectedUser
                  ? selectedUser.username + " " + selectedUser.otherNames + " " + selectedUser.lastName
                  : user
              }
              onChange={(e) => setUser(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {props.accredationUsers?.map((usr) => (
              <Typography
                key={usr.id} // Add a key if not already present
                className={classes.userLable}
                onClick={() => setSelectedUser(usr)}
              >
                {usr.username + " "}
                {usr.otherNames + " "}
                {usr.lastName}
              </Typography>
            ))}
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="end">
          <Button variant="contained" color="primary" onClick={handleAssignUser} disabled={!selectedUser}>
            Create Odk User
          </Button>
        </Box>
        <OdkUsersList detailUuid={detailUuid} projectId={projectId} />
      </div>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  mutation: state.accredation.mutation, // Adjust if state is different
  submittingMutation: state.accredation.submittingMutation,
  accredationUsers: state.accredation?.accreditationUsers,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ journalize, createODKUser, fetchAccredationUsers }, dispatch);
};

export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(UsersPanel)))),
);
