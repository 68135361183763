import React, { Component, Fragment } from "react";
import InsureeMasterPanel from "./InsureeMasterPanel";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Contributions, PublishedComponent, formatMessage, withModulesManager, decodeId } from "@openimis/fe-core";
import { PersonAdd as AddExistingIcon } from "@material-ui/icons";
import { fetchInsureeFull } from "../actions";

const POLICY_POLICY_PANELS_CONTRIBUTION_KEY = "policy.Policy.panels";

class HeadInsureeMasterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edited: props.edited || {},
    };
  }

  updateAttribute = (key, value) => {
    this.setState((prevState) => ({
      edited: {
        ...prevState.edited,
        [key]: value,
      },
    }));
    if (this.props.onEditedChanged) {
      this.props.onEditedChanged({
        ...this.state.edited,
        [key]: value,
      });
    }
  };

  onEditedChanged = (head) => {
    let edited = { ...this.props.edited };
    edited["headInsuree"] = head;
    this.props.onEditedChanged(edited);
    if (head && head.uuid) {
      this.props.dispatch(fetchInsureeFull(this.props.modulesManager, head.uuid));
    }
  };

  render() {
    const { intl, edited, edited_id, readOnly, fetchingPolicyValues, user } = this.props;
    const { edited: stateEdited } = this.state;

    return (
      <Fragment>
        <InsureeMasterPanel
          {...this.props}
          edited={!!edited ? edited.headInsuree : null}
          onEditedChanged={this.onEditedChanged}
          title="enrollee.HeadEnrolleeMasterPanel.title"
        />

        <div
          style={{
            padding: "20px",
            border: "1px solid #ddd",
            borderRadius: "8px",
            backgroundColor: "#b5cdd1",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h3 style={{ marginBottom: "16px", fontWeight: "normal" }}>
            {formatMessage(intl, "policy", "Policy Details")}
          </h3>
          <hr style={{ border: "none", borderTop: "1px solid #0000", margin: "16px 0" }} />

          <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            {/* Enroll Date and Effective Date */}
            <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
              <div style={{ flex: 1, minWidth: "200px" }}>
                <PublishedComponent
                  pubRef="core.DatePicker"
                  value={stateEdited.enrollDate || null}
                  module="policy"
                  label="Policy.enrollDate"
                  minDate={new Date().setDate(new Date().getDate() - 30)}
                  readOnly={false}
                  required={true}
                  onChange={(v) => this.updateAttribute("enrollDate", v)}
                />
              </div>
              <div style={{ flex: 1, minWidth: "200px" }}>
                <PublishedComponent
                  pubRef="core.DatePicker"
                  value={stateEdited.effectiveDate || null}
                  module="policy"
                  label="Policy.effectiveDate"
                  readOnly={true}
                  required={false}
                  onChange={(v) => this.updateAttribute("effectiveDate", v)}
                />
              </div>
            </div>

            {/*Start Date and Expiry Date */}
            <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
              {!fetchingPolicyValues &&
                ["startDate", "expiryDate"].map((date) => (
                  <div key={`policy-${date}`} style={{ flex: 1, minWidth: "200px" }}>
                    <PublishedComponent
                      pubRef="core.DatePicker"
                      value={stateEdited[date] || null}
                      module="policy"
                      label={`Policy.${date}`}
                      readOnly={true}
                      onChange={(v) => this.updateAttribute(date, v)}
                    />
                  </div>
                ))}
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
              <div style={{ flex: 1, minWidth: "200px" }}>
                <PublishedComponent
                  pubRef="product.ProductPicker"
                  value={stateEdited.product || null}
                  module="policy"
                  readOnly={!!edited_id || readOnly}
                  withNull={true}
                  label={formatMessage(intl, "product", "Product")}
                  withLabel={true}
                  nullLabel={formatMessage(intl, "product", "Product.none")}
                  withPlaceholder={true}
                  placeholder={formatMessage(intl, "product", "ProductPicker.placeholder")}
                  onChange={(v) => this.updateAttribute("product", v)}
                  required={true}
                />
              </div>

              {/* Restrict officer to logged-in officer(s) */}
              <div style={{ flex: 1, minWidth: "200px" }}>
                <PublishedComponent
                  pubRef="insuree.InsureeOfficerPicker"
                  value={stateEdited.officer || null}
                  module="insuree"
                  readOnly={readOnly}
                  withPlaceholder={true}
                  withLabel={true}
                  label={formatMessage(intl, "insuree", "InsureeOfficerPicker.label")}
                  placeholder={formatMessage(intl, "insuree", "InsureeOfficerPicker.placeholder")}
                  withNull={true}
                  nullLabel={formatMessage(intl, "insuree", "InsureeOfficer.none")}
                  onChange={(v) => this.updateAttribute("officer", v)}
                  required={true}
                  filters={{
                    officerId: user?.id,
                  }}
                />
              </div>
            </div>

            {/* Policy Status */}
            <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
              <div style={{ flex: 1, minWidth: "200px" }}>
                <PublishedComponent
                  pubRef="policy.PolicyStatusPicker"
                  value={stateEdited.status || null}
                  module="policy"
                  readOnly={true}
                  withNull={true}
                  nullLabel="PolicyStatus.none"
                  onChange={(v) => this.updateAttribute("status", v)}
                />
              </div>
            </div>
          </div>
        </div>

        <Contributions
          {...this.props}
          updateAttribute={this.updateAttribute}
          contributionKey={POLICY_POLICY_PANELS_CONTRIBUTION_KEY}
        />
      </Fragment>
    );
  }
}

export default withModulesManager(connect((state) => ({ user: state.core.user }))(injectIntl(HeadInsureeMasterPanel)));
