import React from 'react';
import { Box } from "@material-ui/core"

class ErrorModal extends React.Component {
    render() {

        const jsonString = this.props.data?.error

        // Parse the JSON string into a JavaScript object
        const jsonObject = JSON.parse(jsonString);
        // Extract status and data
        const status = jsonObject.status;
        const data = jsonObject.data;

        return (
            <Box className="modal">
                <div className="modal-content">
                    {status === 1 && (
                        <>
                            <div>
                                <h2>Wrong CHFid:</h2>
                                <ul>
                                    {data.filter(([msg, code]) => msg === "wrong CHFid").map(([msg, code], index) => (
                                        <li key={index}>{code}</li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <h2>Already Exist:</h2>
                                <ul>
                                    {data.filter(([msg, code]) => msg === "already exist").map(([msg, code], index) => (
                                        <li key={index}>{code}</li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                    {!status && <p>All insurees added successfully</p>}
                </div>
            </Box>
        );
    }
}

export default ErrorModal;
