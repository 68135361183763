import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { fetchOdkUser, fetchAccredationDetailForms, assignFormsToOdkUser, fetchUserQrCode } from "../actions";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { withModulesManager, journalize } from "@openimis/fe-core";
import { bindActionCreators } from "redux";
import QRCodeGenerator from "./QRCodeGenerator";
import AssignFormsUserList from "./AssignFormsUserList";
import { DETAIL_TYPE_ACC } from "../constants";
import { convertJsonToQRcode } from "../helpers";

const styles = (theme) => ({
  title: theme.paper.title,
});

function OdkUsersList(props) {
  const {
    classes,
    odkUsers,
    fetchingODKUsers,
    fetchedODKUsers,
    errorODKUsers,
    detailUuid,
    projectId,
    fetchUserQrCode,
    fetchingQRCode,
    fetchedQRCode,
    errorQRCode,
    QRCode,
    fetchAccredationDetailForms,
  } = props;

  const [openQRCodeModal, setOpenQRCodeModal] = useState(false); // QR Code modal state
  const [selectedQRCode, setSelectedQRCode] = useState(""); // QR code JSON for the modal

  const [openAssignFormModal, setOpenAssignFormModal] = useState(false); // Assign Form modal state
  const [selectedUser, setSelectedUser] = useState(null); // Selected user for the assign forms modal

  useEffect(() => {
    props.fetchOdkUser(detailUuid);
  }, []);

  // Open QR Code modal
  // const handleViewQRCode = (uuid) => {
  //   props.fetchUserQrCode(uuid);
  //   setOpenQRCodeModal(true);
  // };

  const handleViewQRCode = (qrCodeJson) => {
    try {
      // Replace single quotes with double quotes and Python-style False with JavaScript-style false
      const jsonString = qrCodeJson.replace(/'/g, '"').replace(/\bFalse\b/g, "false");

      // Parse the updated string into a JSON object
      const parsedJson = JSON.parse(jsonString);

      // Convert JSON object back to a string for QRCode generation
      const formattedQRCode = convertJsonToQRcode(parsedJson);
      setSelectedQRCode(formattedQRCode);
      setOpenQRCodeModal(true);
    } catch (error) {
      console.error("Failed to parse QR Code JSON:", error);
      setSelectedQRCode(""); // Reset if parsing fails
      setOpenQRCodeModal(true);
    }
  };

  // Close QR Code modal
  const handleCloseQRCodeModal = () => {
    setOpenQRCodeModal(false);
  };

  // Open Assign Form modal
  const handleAssignForm = (user) => {
    setSelectedUser(user);
    setOpenAssignFormModal(true);
  };

  // Close Assign Form modal
  const handleCloseAssignFormModal = () => {
    setOpenAssignFormModal(false);
  };

  const fetchForms = () => {
    fetchAccredationDetailForms(projectId);
  };

  return (
    <Box mt={3}>
      <Typography className={classes.title}>
        {fetchingODKUsers ? "Loading" : errorODKUsers ? "Error Fetching Users" : "User List"}
      </Typography>

      {fetchedODKUsers && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Assign Form to User</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {odkUsers.map((row) => (
                <TableRow key={row.uuid}>
                  <TableCell>
                    {row.user.username + " "}
                    {row.user.otherNames + " "}
                    {row.user.lastName}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="outlined" color="primary" onClick={() => handleAssignForm(row)}>
                      Assign Forms to User
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="outlined" color="primary" onClick={() => handleViewQRCode(row.qrCodeJson)}>
                      View QRCode
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* QR Code Modal */}
      {/* <Dialog open={openQRCodeModal} onClose={handleCloseQRCodeModal} width={1000} fullWidth>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="200px">
            {fetchingQRCode && <p>Loading</p>}
            {fetchedQRCode && (
              <img
                style={{
                  height: 600,
                  width: 600,
                  borderRadius: "16px",
                  boxShadow: 3,
                  objectFit: "cover", // Ensures the image doesn't stretch
                }}
                alt="Example image"
                src={QRCode.qrCodeUri}
              ></img>
            )}
          </Box>
        </DialogContent>
      </Dialog> */}

      <Dialog open={openQRCodeModal} onClose={handleCloseQRCodeModal} maxWidth="sm" fullWidth>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="200px">
            {selectedQRCode ? (
              <QRCodeGenerator value={selectedQRCode} size={500} />
            ) : (
              <Typography>No QR Code Available</Typography>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      {/* Assign Forms Modal */}
      <Dialog open={openAssignFormModal} onClose={handleCloseAssignFormModal} maxWidth="sm" fullWidth>
        <DialogTitle>Assign Forms to {selectedUser?.user?.username}</DialogTitle>
        <DialogContent>
          <AssignFormsUserList
            projectId={projectId}
            detailUuid={detailUuid}
            userUuid={selectedUser?.uuid}
            detailType={DETAIL_TYPE_ACC}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  mutation: state.accredation.mutation, // Adjust if state is different
  submittingMutation: state.accredation.submittingMutation,
  odkUsers: state.accredation.odkUsers,
  fetchingODKUsers: state.accredation.fetchingODKUsers,
  fetchedODKUsers: state.accredation.fetchedODKUsers,
  errorODKUsers: state.accredation.errorODKUsers,
  accreditationDetailsForms: state.accredation.accreditationDetailsForms,
  fetchingAccreditationDetailsForms: state.accredation.fetchingAccreditationDetailsForms,
  fetchedAccreditationDetailsForms: state.accredation.fetchedAccreditationDetailsForms,
  errorAccreditationDetailsForms: state.accredation.errorAccreditationDetailsForms,
  QRCode: state.accredation.QRCode,
  fetchingQRCode: state.accredation.fetchingQRCode,
  fetchedQRCode: state.accredation.fetchedQRCode,
  errorQRCode: state.accredation.errorQRCode,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ journalize, fetchOdkUser, fetchAccredationDetailForms, fetchUserQrCode }, dispatch);
};

export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(OdkUsersList)))),
);
