import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withTheme, withStyles, Box, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { Helmet, withModulesManager, journalize } from "@openimis/fe-core";
import { bindActionCreators } from "redux";
import { fetchAccredationUsers } from "../actions";

const styles = (theme) => ({
  page: {
    ...theme.page,
    display: "flex",
    justifyContent: "center", // Optional: Center horizontally
    alignItems: "center", // Optional: Center vertically
    padding: theme.spacing(2),
  },
  formControl: {
    width: "100%", // Ensures it takes the full width
    margin: theme.spacing(2, 0),
  },
});

function AccreditationUserPicker(props) {
  const { classes, accredationUsers, intl, fetchAccredationUsers, onChange, selectedUser } = props;

  useEffect(() => {
    fetchAccredationUsers();
  }, []);

  return (
    <Box className={classes.page}>
      <Helmet title={intl.formatMessage({ id: "accreditation.userPicker.title" })} />
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel id="user-picker-label">{intl.formatMessage({ id: "accreditation.userPicker.label" })}</InputLabel>
        <Select labelId="user-picker-label" value={selectedUser?.username} onChange={onChange} fullWidth>
          {accredationUsers?.map((user, idx) => (
            <MenuItem key={idx} value={user.id || ""}>
              {user.username + " " + user.otherNames + " " + user.lastName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  rights: state?.core?.user?.i_user?.rights || [],
  mutation: state.accredation?.mutation,
  submittingMutation: state.accredation?.submittingMutation,
  accredationUsers: state.accredation?.accreditationUsers,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ journalize, fetchAccredationUsers }, dispatch);
};

export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(AccreditationUserPicker)))),
);
