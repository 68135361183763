import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, Typography, Paper, Grid } from "@material-ui/core";
import { fetchAccreditationFormCalculation } from "../actions";
import CalculationResults from "./resultsCalculation";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    marginTop: theme.spacing(2),
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: theme.spacing(4),
  },
  errorText: {
    color: theme.palette.error.main,
  },
  calculationItem: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
});

class AccreditationFormCalculation extends Component {
  componentDidMount() {
    const { accreditationDetailFormUuid, odkInstanceId, fetchAccreditationFormCalculation } = this.props;

    if (accreditationDetailFormUuid && odkInstanceId) {
      fetchAccreditationFormCalculation(accreditationDetailFormUuid, odkInstanceId);
    } else {
      console.warn("Missing accreditationDetailFormUuid or odkInstanceId");
    }
  }

  renderCalculationDetails() {
    const { classes, accreditationFormCalculation } = this.props;

    if (!accreditationFormCalculation) return null;

    if (Array.isArray(accreditationFormCalculation)) {
      return accreditationFormCalculation.map((calculation, index) => (
        <Grid container key={index} className={classes.calculationItem}>
          {Object.entries(calculation).map(([key, value]) => (
            <React.Fragment key={key}>
              <Grid item xs={6}>
                <Typography variant="body2">{key}:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{String(value)}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ));
    }

    if (typeof accreditationFormCalculation === "object") {
      return (
        <Grid container>
          {Object.entries(accreditationFormCalculation).map(([key, value]) => (
            <React.Fragment key={key}>
              <Grid item xs={6}>
                <Typography variant="body2">{key}:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{String(value)}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      );
    }

    return null;
  }

  render() {
    const { classes, fetchingCalculations, error, accreditationFormCalculation } = this.props;

    if (fetchingCalculations) {
      return (
        <Box className={classes.loadingContainer}>
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return <Typography className={classes.errorText}>Error fetching calculations: {error}</Typography>;
    }

    return (
      <Paper className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Calculation Results
        </Typography>
        {accreditationFormCalculation ? (
          <div>
            {this.renderCalculationDetails()}
            <CalculationResults
              calculations={
                Array.isArray(accreditationFormCalculation)
                  ? accreditationFormCalculation
                  : [accreditationFormCalculation]
              }
            />
          </div>
        ) : (
          <Typography variant="body1">No calculation results available</Typography>
        )}
      </Paper>
    );
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    accreditationDetailFormUuid: PropTypes.string,
    odkInstanceId: PropTypes.string,
    fetchAccreditationFormCalculation: PropTypes.func.isRequired,
    fetchingCalculations: PropTypes.bool,
    error: PropTypes.string,
    accreditationFormCalculation: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  };

  static defaultProps = {
    accreditationFormCalculation: null,
    fetchingCalculations: false,
    error: null,
  };
}

const mapStateToProps = (state) => ({
  accreditationFormCalculation: state.accreditation?.accreditationFormCalculation || null,
  fetchingCalculations: state.accreditation?.fetchingCalculations || false,
  error: state.accreditation?.calculationError || null,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchAccreditationFormCalculation }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccreditationFormCalculation));
