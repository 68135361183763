import React from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";

const PercentageChart = ({ value, maxValue, label }) => {
  const percentage = Math.round((value / maxValue) * 100);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" m={2}>
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" value={percentage} size={100} thickness={4} color="primary" />
        <Box
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h6" component="div" color="textSecondary">
            {`${percentage}%`}
          </Typography>
        </Box>
      </Box>
      {label && (
        <Typography variant="body1" style={{ marginTop: 8 }}>
          {label}
        </Typography>
      )}
    </Box>
  );
};

PercentageChart.propTypes = {
  value: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  label: PropTypes.string,
};

PercentageChart.defaultProps = {
  label: "",
};

export default PercentageChart;
