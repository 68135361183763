import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles, withTheme, Paper, Typography, FormControlLabel, Checkbox, Box, Button } from "@material-ui/core";
import { fetchAccredationDetailForms, assignFormsToOdkUser, fetchOdkUserAssignment } from "../actions"; // Assuming this action exists

const styles = (theme) => ({
  paper: theme.paper.paper,
  title: theme.paper.title,
  page: theme.page,
});

class AssgnFormsUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formToPublish: [], // Initialize the state to hold selected form UUIDs
    };
  }

  componentDidMount() {
    const { projectId, detailUuid, userUuid } = this.props;
    if (projectId) {
      this.props.fetchAccredationDetailForms(projectId);
    }
    if (userUuid) {
      this.props.fetchOdkUserAssignment(userUuid);
    }
    // Fetch the forms when the component mounts
  }

  handleCheckboxChange = (uuid) => {
    this.setState((prevState) => {
      const { formToPublish } = prevState;

      // Check if the UUID is already in the array
      if (formToPublish.includes(uuid)) {
        // Remove it if it's already there
        return { formToPublish: formToPublish.filter((id) => id !== uuid) };
      } else {
        // Add it if it's not there
        return { formToPublish: [...formToPublish, uuid] };
      }
    });
  };

  handleDeployForms = () => {
    const { detailUuid, assignFormsToOdkUser, detailType, userUuid } = this.props;
    const { formToPublish } = this.state;
    assignFormsToOdkUser(formToPublish, detailUuid, userUuid, detailType, "Assigned From To User");
  };

  isDeployForms = () => !this.state.formToPublish.length || !this.props.detailUuid;

  render() {
    const { classes, accreditationDetailsForms, accDetailUuid, odkUserAssignments } = this.props;
    const { formToPublish } = this.state;

    return (
      <div style={{ width: "100%" }}>
        <Typography className={classes.title}>Forms List</Typography>
        <div className={""}>
          {accreditationDetailsForms?.map((form, index) => (
            <div style={{ width: "100%" }}>
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    color="primary"
                    value={form.uuid}
                    onChange={() => this.handleCheckboxChange(form.uuid)}
                    checked={formToPublish.includes(form.uuid)} // Check if the form UUID is selected
                  />
                }
                label={form.odkForm.formName}
              />
            </div>
          ))}
          <Box display={"flex"} justifyContent={"end"}>
            <Button
              variant={"contained"}
              onClick={this.handleDeployForms}
              color="primary"
              disabled={this.isDeployForms()}
            >
              Assign Forms
            </Button>
          </Box>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accreditationDetailsForms: state.accredation.accreditationDetailsForms,
  fetchingAccreditationDetailsForms: state.accredation.fetchingAccreditationDetailsForms,
  fetchedAccreditationDetailsForms: state.accredation.fetchedAccreditationDetailsForms,
  errorAccreditationDetailsForms: state.accredation.errorAccreditationDetailsForms,
  odkUserAssignments: state.accredation.odkUserAssignments,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchAccredationDetailForms, assignFormsToOdkUser, fetchOdkUserAssignment }, dispatch);

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AssgnFormsUserList)));
