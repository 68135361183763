import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import clsx from "clsx";

import {
  withTheme,
  withStyles,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import {
  ProgressOrError,
  withModulesManager,
  journalize,
  withHistory,
  formatDateFromISO,
  formatMessage,
} from "@openimis/fe-core";
import { fetchAllForms, createFormConfiguration } from "../actions";

const styles = (theme) => ({
  paper: theme.paper.paper,
  title: theme.paper.title,
  page: theme.page,
});

class AllForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFormName: null,
      selectFormUuid: "",
      anchorEl: null,
      openConfigModal: false,
      configValues: [],
    };
  }

  componentDidMount() {
    this.props.fetchAllForms();
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleRowClick = (form) => {
    // Prevent deselecting the form if it's already selected and we are in the configuration modal
    this.setState((prevState) => ({
      selectedFormName:
        prevState.selectedFormName === form.formName && !prevState.openConfigModal ? null : form.formName,
      selectFormUuid: prevState.selectFormUuid === form.uuid && !prevState.openConfigModal ? null : form.uuid,
    }));
  };

  handleAddConfigClick = (form) => {
    this.setState({
      selectedFormName: form.formName,
      selectFormUuid: form.uuid, // Set UUID here
      openConfigModal: true,
      configValues: form?.odkformconfig?.calculationParameters
        ? form?.odkformconfig?.calculationParameters.replace(/[\[\]"]/g, "").split(",")
        : [],
    });
  };

  handleCloseConfigModal = () => {
    this.setState({ openConfigModal: false, configValues: [] });
  };

  handleSaveConfigModal = () => {
    const { selectFormUuid, configValues } = this.state;

    if (!selectFormUuid) {
      console.error("Form UUID is missing. Cannot save configuration.");
      return;
    }

    // Dispatch the action with the collected parameters
    this.props.createFormConfiguration(configValues, selectFormUuid, "Created Form Configuration");

    // Close the modal and reset the state
    this.setState({ openConfigModal: false, configValues: [] });
  };

  handleAddParam = () => {
    this.setState((prevState) => ({
      configValues: [...prevState.configValues, ""],
    }));
  };

  handleRemoveParam = (index) => {
    this.setState((prevState) => ({
      configValues: prevState.configValues.filter((_, i) => i !== index),
    }));
  };

  handleParamChange = (index, event) => {
    const newParams = [...this.state.configValues];
    newParams[index] = event.target.value;
    this.setState({ configValues: newParams });
  };

  render() {
    const { classes, allForms } = this.props;
    const { anchorEl, selectedFormName, openConfigModal } = this.state;

    // Find the selected form object based on selectedFormName
    const selectedForm = allForms?.find((form) => form.formName === selectedFormName);

    return (
      <>
        <Paper className={classes.paper}>
          <div
            className={classes.title}
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "50px" }}
          >
            <Typography>Forms</Typography>
            {selectedForm && (
              <IconButton onClick={this.handleMenuOpen}>
                <MoreVertIcon />
              </IconButton>
            )}

            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={this.handleMenuClose}>
              <MenuItem onClick={() => this.handleAddConfigClick(selectedForm)}>Add configurations</MenuItem>
            </Menu>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Form Name</TableCell>
                <TableCell>Form Type</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allForms?.map((form) => (
                <TableRow
                  key={form.formName}
                  hover
                  onClick={() => this.handleRowClick(form)}
                  selected={selectedFormName === form.formName}
                  style={{
                    cursor: "pointer",
                    backgroundColor: selectedFormName === form.formName ? "#f5f5f5" : "inherit",
                  }}
                >
                  <TableCell>{form.formName}</TableCell>
                  <TableCell>{formatMessage(this.props.intl, "accredation", `form.type.${form.formType}`)}</TableCell>
                  <TableCell>
                    {formatDateFromISO(this.props.modulesManager, this.props.intl, form.createdDate)}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click when clicking button
                        this.handleAddConfigClick(form);
                      }}
                    >
                      Form Configuration
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <Dialog open={openConfigModal} onClose={this.handleCloseConfigModal}>
          <DialogTitle>Add Configurations</DialogTitle>
          <DialogContent>
            <Typography>Configurations for: {selectedForm?.formName}</Typography>

            {/* Check if odkFormconfig is not null */}
            {selectedForm?.odkformconfig ? (
              <>
                <Typography variant="subtitle1">
                  Calculation Parameters: {selectedForm?.odkformconfig.calculationParameters.replace(/[\[\]"]/g, "")}
                </Typography>
                <Typography variant="subtitle1">
                  Created Date:{" "}
                  {selectedForm?.odkformconfig.createdDate
                    ? new Date(selectedForm?.odkformconfig.createdDate).toLocaleDateString()
                    : "N/A"}
                </Typography>
                <Typography variant="subtitle1">
                  Updated Date:{" "}
                  {selectedForm?.odkformconfig.updatedDate
                    ? new Date(selectedForm?.odkformconfig.updatedDate).toLocaleDateString()
                    : "N/A"}
                </Typography>
              </>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No configuration found for this form.
              </Typography>
            )}

            {/* Configuration parameters (configValues) */}
            {this.state.configValues.map((param, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                <TextField
                  label={`Parameter ${index + 1}`}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  value={param}
                  onChange={(e) => this.handleParamChange(index, e)}
                />
                <IconButton onClick={() => this.handleRemoveParam(index)} color="secondary">
                  ✕
                </IconButton>
              </div>
            ))}

            <Button onClick={this.handleAddParam} color="primary" variant="outlined">
              Add Calculation Parameter
            </Button>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleCloseConfigModal} color="primary">
              Close
            </Button>
            <Button onClick={this.handleSaveConfigModal} color="primary" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  mutation: state.accredation.mutation,
  fetchingAllForms: state.accredation.fetchingAllForms,
  fetchedAllForms: state.accredation.fetchedAllForms,
  allForms: state.accredation.allForms,
  errorAllForms: state.accredation.errorAllForms,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ journalize, fetchAllForms }, dispatch);
};

export default withModulesManager(
  injectIntl(withTheme(withStyles(styles)(withHistory(connect(mapStateToProps, mapDispatchToProps)(AllForms))))),
);
